import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, URLS } from '../../constants';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { User, useUser } from '../../context/UserContext';
import InfoBox from '../../components/InfoBox';
import Select from '../../components/Select';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import MultiSelect from '../../components/Multiselect';
import { certificationsMock, companySizes, countries_, industries, productsOfferedMock } from '../../mocks';
import FileUpload from '../../components/FileUpload';

const SignupCompany: React.FC = () => {  
  const { user, setUser } = useUser();

  const userIsInApprovalState = user && user.profileCompleted && !user.profileApproved;

  const [showModal, setShowModal] = useState(userIsInApprovalState);

  const {
    firstname,
    companyName,
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    location,
    address,
    zip,
    companySize,
    website,
    socials
  } = user || {};

  const [formData, setFormData] = useState({
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    location,
    address,
    zip,
    companySize,
    website,
    socials
  });

  
  const handleSaveAccountForLater = async (e: React.FocusEvent) => {
    e.preventDefault();

    const { data } = await saveAccount();

    console.log('account saved: ', data )
  };

const saveAccount = async (): Promise<{ data: User }> => {
    if (!user) return { data: {} as User };

    const userData = {
        ...user,
        overview: formData.overview,
        mission: formData.mission,
        industry: formData.industry,
        productsOffered: formData.productsOffered,
        practiceDescription: formData.practiceDescription,
        certifications: formData.certifications,
        supportingDocs: formData.supportingDocs,
        location: formData.location,
        address: formData.address,
        zip: formData.zip,
        companySize: formData.companySize,
        website: formData.website,
        socials: formData.socials
    };

    setUser(userData)

    return { data: userData }
  };

  const submitAccount = async (): Promise<{ data: User }> => {
    if (!user) return { data: {} as User };

    const { data } = await saveAccount();

    const userData = {
        ...data,
        profileCompleted: true,
    };

    console.log({ userData })

    setUser(userData)
    setShowModal(true)

    return { data: userData }
  };

  const closeModal = () => setShowModal(false);

    return (
        <>
        <Header />
        <Wrapper>
            <div>
                <FormContainer>
                    { !userIsInApprovalState && <InfoBox>
                        Hello {firstname || ''},<br/>
                        To proceed, please complete your company profile with accurate and detailed information.
                        This will help us verify your company and ensure a seamless experience on our platform.
                    </InfoBox> }
                    <FormTitle>{ userIsInApprovalState ? 'Your profile' : 'Create Profile'}</FormTitle>
                    <StyledForm onSubmit={submitAccount}>
                        <InputsContainer>
                        <Column>
                            <Input
                                label="Company Name"
                                name="companyName"
                                value={companyName || ''}
                                disabled
                            />

                            <Input
                                label="Company overview (Max 300 characters)"
                                name="overview"
                                textarea
                                maxLength={300}
                                value={formData.overview || ''}
                                onChange={(value) => setFormData({...formData, overview: value})}
                                disabled={!!userIsInApprovalState}
                            />

                            <Input
                                label="Mission statement (Max 200 characters)"
                                name="mission"
                                textarea
                                maxLength={200}
                                value={formData.mission || ''}
                                onChange={(value) => setFormData({...formData, mission: value})}
                                disabled={!!userIsInApprovalState}
                            />

                            <Select 
                                label='Company Industry'
                                name='industry'
                                value={formData.industry || ''}
                                onChange={(e) => setFormData({...formData, industry: e.target.value})}
                                options={industries}
                                disabled={!!userIsInApprovalState}
                            />

                            <MultiSelect
                                label="Products/Services Offered"
                                name="productsOffered"
                                selectedValues={formData.productsOffered ? formData.productsOffered.split(', ') : []}
                                options={productsOfferedMock}
                                onChange={(value) => setFormData({...formData, productsOffered: value.join(', ')})}
                                disabled={!!userIsInApprovalState}
                            />

                            <Input
                                label="Describe your sustainability practice"
                                name="practiceDescription"
                                value={formData.practiceDescription || ''}
                                onChange={(value) => setFormData({...formData, practiceDescription: value})}
                                disabled={!!userIsInApprovalState}
                            />

                            <MultiSelect
                                label="Certifications"
                                name="certifications"
                                selectedValues={formData.certifications ? formData.certifications.split(', ') : []}
                                options={certificationsMock}
                                onChange={(value) => setFormData({...formData, certifications: value.join(', ')})}
                                disabled={!!userIsInApprovalState}
                            />
    
                            <Input
                                label="Your supporting documents"
                                type="file"
                                multiple
                                name="documents"
                                onUpload={(files) => {
                                    const documents = files?.map(file => { return { attachment: file, name: file?.name }});
                                    setFormData({...formData, supportingDocs: documents})
                                }}
                                disabled={!!userIsInApprovalState}
                            />  
                            </Column>
                            
                            <Column>
                                <FormGroup>
                                    <Label htmlFor="role">Company logo</Label>
                                    <FileUpload type='picture' />
                                </FormGroup>

                                <Select 
                                    label='Headquarters Location'
                                    name='location'
                                    value={formData.location || ''}
                                    onChange={(e) => setFormData({...formData, location: e.target.value})}
                                    options={countries_}
                                    disabled={!!userIsInApprovalState}
                                />
            
                                <Input
                                    label="Address"
                                    name="address"
                                    value={formData.address || ''}
                                    onChange={(value) => setFormData({...formData, address: value})}
                                    disabled={!!userIsInApprovalState}
                                />  

                                <Input
                                    label="ZIP/ Postal code"
                                    name="zip"
                                    value={formData.zip || ''}
                                    onChange={(value) => setFormData({...formData, zip: value})}
                                    disabled={!!userIsInApprovalState}
                                />  

                                <Select  
                                    label="Company size"
                                    options={companySizes}
                                    name="companySize"
                                    onChange={(e) => setFormData({...formData, companySize: e.target.value})}
                                    value={formData.companySize || ''}
                                    disabled={!!userIsInApprovalState}
                                />  

                                <Input  
                                    label="Company Website"
                                    name="website"
                                    value={formData.website || ''}
                                    onChange={(value) => setFormData({...formData, website: value})}
                                    disabled={!!userIsInApprovalState}
                                />  

                                <Input  
                                    label="Social Media Account(s)"
                                    name="socials"
                                    textarea
                                    value={formData.socials || ''}
                                    onChange={(value) => setFormData({...formData, socials: value})}
                                    disabled={!!userIsInApprovalState}
                                />  
                            </Column>
                        </InputsContainer>

                        {!userIsInApprovalState
                            ? <ButtonsContainer>
                                <Button onClick={handleSaveAccountForLater}>Save my account to submit later</Button>
                                <Button type="submit">Submit my profile</Button>
                            </ButtonsContainer>
                            : <InfoBox>You have submitted your application</InfoBox> 
                        } 
                        <StyledLink href={URLS.terms} target="_blank">Review terms and conditions on EcoConnect</StyledLink>
                    </StyledForm>
                </FormContainer>
            </div>

            <Modal isOpen={!!showModal} onClose={closeModal}>
                Thank you for submitting your details.<br></br><br></br>
                We will verify the information you provided about your company. This process
                may take up to 2 business days. You will receive a notification once the
                verification is complete. Thank you for your patience and commitment to our
                eco-friendly community.
            </Modal>

        </Wrapper>
        </>
    );
};

export default SignupCompany;

const Wrapper = styled.div`
    margin: 30px;
    min-height: 100vh;

    > div {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

const FormContainer = styled.div`
  max-width: 1200px;
  padding: 25px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const InputsContainer = styled.div`
    display: flex;
    gap: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const Column = styled.div`
    display: flex;   
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    flex-basis: 50%;
`;

const FormTitle = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const StyledForm = styled.form`
`;

const StyledLink = styled.a`
  color: ${colors.darkGreen};
  text-align: center;
  display: block;
  margin: 0 auto;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: 400px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > button {
        margin-bottom: 10px;
    }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;
