import React from 'react';
import styled from 'styled-components';

interface ErrorProps {
    children: React.ReactNode;
}

const StyledError = styled.div`
    color: red;
    margin-bottom: 30px;
    font-size: 14px;
`;

const Error: React.FC<ErrorProps> = ({ children }) => {
    return (
        <StyledError>{children}</StyledError>
    );
};

export default Error;