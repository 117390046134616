import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../context/UserContext';
import { colors } from '../../constants';
import { FaSignOutAlt } from 'react-icons/fa';

const Header: React.FC = () => {
    const { user, logout } = useUser();

    return (
        <HeaderWrapper>
            <Logo>
                <img src="/assets/logo.png" alt="Ecoconnect Logo" />
            </Logo>
            { user && <UserName>
                {user?.companyName} <Logout><FaSignOutAlt onClick={logout}/></Logout>
            </UserName> }
            
        </HeaderWrapper>
    );
};

export default Header;

const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    width: 50px;

    img {
        width: 100%;
    }
`;

const UserName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.darkGreen};
`;

const Logout = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;
