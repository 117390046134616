import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import { colors, TABS } from '../../constants';
import Header from '../../components/Header';

const Contracts: React.FC = () => {

  return (
    <>
    <Header />
    <Container>
      <Sidebar tab={TABS.contracts} />
      
      <Content>
        <Title>Contracts</Title>
        <Table>
         
        </Table>
      </Content>
    </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;

const Table = styled.div`
  background-color: #ffffff;
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

export default Contracts;