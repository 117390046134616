import React from 'react';
import { colors } from '../../constants';
import styled from 'styled-components';

const StyledButton = styled.button`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: ${colors.darkGreen};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.darkGreenHover};
  }

  &:active {
    background-color: ${colors.darkGreenHover};
  }
`;

interface ButtonProps {
    onClick?: (e: any) => void;
    children?: React.ReactNode;
    type?: "button" | "submit" | "reset";
    light?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, type, onClick }) => {
    return (
        <StyledButton type={type} onClick={onClick}>{ children }</StyledButton>
    );
};

export default Button;