import React, { useState } from 'react';
import Select from '../../../components/Select';
import { Title } from '../../../App';
import Input from '../../../components/Input';
import styled from 'styled-components';
import { documentTypes } from '../../../mocks';
import Button from '../../../components/Button';
import { SupplierDocument } from '../../../interfaces';

interface AddNewDocumentModalProps {
    onSave: (document: SupplierDocument ) => void;
}

const AddNewDocumentModal:React.FC<AddNewDocumentModalProps> = ({ onSave }) => {
    const [documentType, setDocumentType] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [documentExpiry, setDocumentExpiry] = useState('');
    const [attachment, setAttachment] = useState<File>();

    const save = () => {
        const document = {
            type: documentType,
            name: documentName,
            expiry: documentExpiry,
            attachment
        }
        onSave(document);
    }

    const uploadFile = async (files: File[] | null) => {
        const file = files?.[0];
        if (file) {
          setAttachment(file);
        }
      };

    return (
       <Wrapper>

            <Title>Add new document</Title>

            <div>
                <InputWrapper >
                    <label htmlFor='type'>Document Type</label>
                    <Select options={documentTypes} name='type' value={documentType} onChange={(e) => setDocumentType(e.target.value)}/>
                </InputWrapper>
                <InputWrapper >
                    <label htmlFor='name'>Document name</label>
                    <Input name='name' value={documentName} onChange={(value) => setDocumentName(value)}/>
                </InputWrapper>
                <InputWrapper >
                    <label htmlFor='expiry'>Document expiry</label>
                    <Input name='expiry' type="date" value={documentExpiry} onChange={(value) => setDocumentExpiry(value)}/>
                </InputWrapper>
                <InputWrapper >
                    <label htmlFor='attachment'>Attach document</label>
                    <Input name='attachment' type="file" onUpload={(files) => uploadFile(files)}/>
                </InputWrapper>
            </div>

            <ButtonContainer>
                <Button onClick={save}>Save</Button>
            </ButtonContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;

    > label {
        width: 200px;
    }

    > div {
        margin-bottom: 0;
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 30px;
`;

export default AddNewDocumentModal;
