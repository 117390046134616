import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

interface RoundButtonProps {
    text?: string;
    onClick?: () => void;
    size?: number;
}

const RoundButton: React.FC<RoundButtonProps> = ({ text, onClick, size }) => {
    return (
        <AddNewSupplierButton onClick={onClick} >
            <PlusIcon size={size}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"/>
              </svg>
              </PlusIcon>
            {text && <Link>{text}</Link>}
          </AddNewSupplierButton>
    );
};

export default RoundButton;

const AddNewSupplierButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;

const PlusIcon = styled.div<{ size?: number }>`
  background-color: ${colors.darkGreen};
  border-radius: 50%;
  width: ${props => props.size || 60}px;
  height: ${props => props.size || 60}px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 40px;
    height: 40px;
    fill: white; /* Change icon color if needed */
  }
`;


const Link = styled.div`
  color: ${colors.darkGreen};
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
`;