import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { colors } from "../../constants";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // import Quartz theme

interface TableProps {
    rowData: any[];
    columns: any[];
    onRowClick?: (row: any) => void;
    height?: number;
}
const Table = ({ rowData, columns, onRowClick, height }: TableProps) => {
    
    return (<TableContainer
                className="ag-theme-quartz"
                height={height}
              >
                <AgGridReact
                  columnDefs={columns}
                  rowData={rowData}
                  defaultColDef={{
                    resizable: true,
                    flex: 1,
                  }}
                  onRowClicked={onRowClick}
                  rowSelection="multiple"
                  suppressRowClickSelection={false}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 25, 50]}
                />
              </TableContainer>)
}

export default Table;

const TableContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => height ? `${height}px` : undefined};
  .ag-root-wrapper {
    border-radius: 4px;
  }
  .ag-row-hover, .ag-row-selected {
    background-color: #e9ffdd;
    cursor: pointer;
  }
  
  .ag-header-row {
    color: ${colors.darkGreen};
    font-weight: bold;
  }

  .ag-paging-panel {
    font-size: 13px;
    border-top: 0;
  }

  .ag-picker-field-wrapper {
    border: none;
  }

  .ag-header-cell-resize:last-child {
    display: none;
  }

  .ag-paging-row-summary-panel,
  .ag-label,
  .ag-paging-description > span:first-child {
    @media screen and (max-width: 1000px) {
        display: none;
    }
  }

  .ag-paging-description > span:first-child {
    display: none;
  }

  .ag-paging-row-summary-panel {
    margin: 0;
  }
  
  .ag-paging-panel > * {
    @media screen and (max-width: 1000px) {
       margin: 0;
    }
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none;
  }
`;