import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import StyledInput from '../../components/Input';
import Button from '../../components/Button';
import InfoBox from '../../components/InfoBox';
import Error from '../../components/Error';

import { colors, URLS } from '../../constants';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleForgotPassword = async () => {
        console.log('Forgot password');
    };

    return (
        <Wrapper>
            <div>
                <Logo>
                    <img src="/assets/logo.png" alt="Ecoconnect Logo" />
                </Logo>
                <FormContainer>
                    <Form>
                        <InfoBox>
                        Forgot your password? Reset your password and reconnect with the EcoConnect community.
                        </InfoBox>
                        <StyledInput
                            label="Business email"
                            name="email"
                            value={email}
                            onChange={(value) => setEmail(value)}
                        />
                        {error && <Error>{error}</Error>}
                        {successMessage && <Success>{successMessage}</Success>}
                        <Button onClick={handleForgotPassword}>Reset password</Button>
                    </Form>
                    <LoginLink>
                        <StyledLink href={URLS.login}>Back to Log in</StyledLink>
                    </LoginLink>
                </FormContainer>
            </div>
        </Wrapper>
    );
};

export default ForgotPassword;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
`;

const Form = styled.div`
  width: 350px;
  margin: 0 auto;
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 15px;
`;

const StyledLink = styled.a`
  color: ${colors.darkGreen};
  text-decoration: none;
  font-weight: bold;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    padding-bottom: 20px;

    img {
        width: 100%;
    }
`;

const Success = styled.p`
    color: green;
    font-size: 14px;
    text-align: center;
`;
