import React  from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AppProvider } from '../context/AppContext';
import { SupplierProvider } from '../context/SupplierContext';
import { UserProvider, useUser } from '../context/UserContext';
import Login from '../pages/Login';
import FindSupplier from '../pages/FindSupplier/MainView';
import Signup from '../pages/Signup';
import Dashboard from '../pages/Dashboard';
import Contracts from '../pages/Contracts';
import SignupCompany from '../pages/Signup/company';
import SupplierDetails from '../pages/FindSupplier/DetailsView';
import Terms from '../pages/TermsAndConditions';
import AddSupplier from '../pages/FindSupplier/AddSupplier';
import FirstLogin from '../pages/FirstLogin';
import ForgotPassword from '../pages/ForgotPassword';
import Profile from '../pages/Profile';
import { URLS } from '../constants';

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <AppProvider>
                <UserProvider>
                    <Routes>
                        <Route path={URLS.login} element={<Login />} />
                        <Route path={URLS.signup} element={<Signup />} />
                        <Route path={URLS.forgotPassword} element={<ForgotPassword />} />

                        <Route path={URLS.dashboard} element={<PrivateRoute/>}>
                            <Route path={URLS.dashboard} element={<Dashboard/>}/>
                        </Route>

                        <Route path={URLS.profile} element={<PrivateRoute/>}>
                            <Route path={URLS.profile} element={<Profile />}/>
                        </Route>

                        <Route path={URLS.contracts} element={<PrivateRoute/>}>
                            <Route path={URLS.contracts} element={<Contracts/>}/>
                        </Route>

                        <Route path={URLS.signupCompany} element={<PrivateRoute/>}>
                            <Route path={URLS.signupCompany} element={<SignupCompany />}/>
                        </Route>

                        <Route path={URLS.firstLogin} element={<PrivateRoute/>}>
                            <Route path={URLS.firstLogin} element={<FirstLogin />}/>
                        </Route>

                        <Route path={URLS.home} element={<Navigate to={URLS.login} />} />

                        <Route path={URLS.terms} element={<Terms />} />
                    </Routes>

                    <SupplierProvider>
                        <Routes>
                            <Route path={URLS.suppliers} element={<PrivateRoute/>}>
                                <Route path={URLS.suppliers} element={<FindSupplier/>}/>
                            </Route>

                            <Route path={URLS.supplierDetails} element={<PrivateRoute/>}>
                                <Route path={URLS.supplierDetails} element={<SupplierDetails/>}/>
                            </Route>

                            <Route path={URLS.addSupplier} element={<PrivateRoute/>}>
                                <Route path={URLS.addSupplier} element={<AddSupplier/>}/>
                            </Route>
                        </Routes>
                    </SupplierProvider>
                </UserProvider>
            </AppProvider>

          
        </Router>
                    
    );
};

const PrivateRoute = () => {
    const { user } = useUser();

    return user ? <Outlet /> : <Navigate to={URLS.login} />;
}

export default AppRoutes;