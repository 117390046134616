import React, { useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../constants';
import styled from 'styled-components';

const Content = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 15px;
    color: #333;
    max-width: 800px;
    padding: 20px;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

const Logo  = styled.div`
    img {
        width: 200px;
    }
`;

const Text = styled.div`
    text-align: center;
    p {
        padding-bottom: 10px;
    }
`;

const FirstLogin: React.FC = () => {

    const { user, setUser} = useUser()

    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            setUser({ ...user, firstLogin: false });
        }
    }, []);

    const { firstname, companyName } = user || {};
    return (
        <Content>
            <Logo>
                <img src="/assets/logo.png" alt="Ecoconnect Logo" />
            </Logo>
           <Text>
                <p>Hello { firstname },</p>
                <p>We are happy to have { companyName }! Let&apos;s start by adding your team and defining their roles.....</p>
            </Text>
           <ButtonContainer>
                <Button>Add my team</Button>
                <Button onClick={() => navigate(URLS.dashboard)}>Go home</Button>
           </ButtonContainer>
        </Content>
    );
};

export default FirstLogin;