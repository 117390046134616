import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

interface ModalProps {
    isOpen: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 100;
`;

const ModalContent = styled.div`
    max-width: 600px;
    width: 90%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    color: black;
    font-size: 14px;
    position: relative;
`;

const Close = styled.div`
    cursor: pointer;
    text-align: right;
    text-decoration: underline;
    position: absolute; 
    top: 15px;
    right: 15px;
`;


const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClose = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsAnimating(false);
            onClose && onClose();
        }, 100);
    };

    const handleCloseOnEsc = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            handleClose()
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleCloseOnEsc, false);
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <Wrapper onClick={handleClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                { onClose && <Close onClick={handleClose}><FaTimes size={15} /></Close>}

                {children}
            </ModalContent>
        </Wrapper>
    );
};

export default Modal;