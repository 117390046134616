import React, { useState } from 'react';
import { FaRegStar, FaRegStarHalf } from 'react-icons/fa';
import styled from 'styled-components';

const RatingContainer = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  flex-direction: row-reverse;
`;

const StarLabel = styled.label`
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  color: #858383;
  cursor: pointer;
  position: relative;

  &.full {
  }

  &.half {
    z-index: 1;
    position: absolute;
  }
`;

const RatingInput = styled.input`
  display: none;
`;

const RatingStyle = styled.div`
  .rating > input:checked ~ label svg {
    color: #03580e;
  }

  .rating:not(:checked) > label:hover svg,
  .rating:not(:checked) > label:hover ~ label svg{
    color: #03580e;
  }

  .rating > input:checked + label:hover,
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label svg{
    color: #03580e;
  }
`;

const StarRating: React.FC = () => {
  const [rating, setRating] = useState<string>('');

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRating(e.target.value);
  };

  return (
    <RatingStyle>
      <RatingContainer className="rating">
        <RatingInput type="radio" id="star5" name="rating" value="5" onChange={handleRatingChange} />
        <StarLabel className="full" htmlFor="star5" title="5 stars">
            <FaRegStar></FaRegStar>
        </StarLabel>

        <RatingInput type="radio" id="star4" name="rating" value="4" onChange={handleRatingChange} />
        <StarLabel className="full" htmlFor="star4" title="4 stars">
            <FaRegStar></FaRegStar>
        </StarLabel>

        <RatingInput type="radio" id="star3" name="rating" value="3" onChange={handleRatingChange} />
        <StarLabel className="full" htmlFor="star3" title="3 stars">
            <FaRegStar></FaRegStar>
        </StarLabel>

        <RatingInput type="radio" id="star2" name="rating" value="2" onChange={handleRatingChange} />
        <StarLabel className="full" htmlFor="star2" title="2 stars">
            <FaRegStar></FaRegStar>
        </StarLabel>


        <RatingInput type="radio" id="star1" name="rating" value="1" onChange={handleRatingChange} />
        <StarLabel className="full" htmlFor="star1" title="1 star">
            <FaRegStar></FaRegStar>
        </StarLabel>
      </RatingContainer>
    </RatingStyle>
  );
};

export default StarRating;
