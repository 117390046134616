import React from 'react';
import AppRoutes from './routes/AppRoutes';
import './App.css';
import styled from 'styled-components';
import { colors } from './constants';

const App: React.FC = () => {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
};

export default App;

export const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;

export const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

export const LinkButton = styled.a`
  color: ${colors.darkGreen};
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  padding: 10px;
  border: 1px solid ${colors.darkGreen};
  border-radius: 4px;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkGreen};
    color: #ffffff;
  }
`;