export const users = [
    {
        companyName: 'user_company',
        password: 'pass',
        type: 'company',
        firstname: 'John',
        lastname: 'Doe',
        email: 'comp_new@gmail.com',
        profileCompleted: false,
        profileApproved: false,
    },
    {
        companyName: 'user_company1',
        password: 'pass',
        type: 'company',
        firstname: 'John',
        lastname: 'Doe',
        email: 'comp_firstLogin@gmail.com',
        "overview": "EcoConnect is a sustainability-driven company focused on providing eco-friendly solutions across various industries. We aim to reduce environmental impact by offering sustainable products and services.",
        "mission": "Our mission is to create a greener, more sustainable future by offering eco-friendly products, reducing carbon footprints, and promoting responsible environmental practices.",
        "industry": "apparel",
        "productsOffered": "outdoor-clothing, gear",
        "practiceDescription": "EcoConnect adheres to sustainable practices such as reducing waste, promoting recycling, using renewable energy in operations, and ensuring ethical sourcing of materials.",
        "certifications": "fair-trade-certified, bluesign-approved",
        "location": "USA",
        profileCompleted: true,
        profileApproved: true,
        firstLogin: true
    },
    {
        "companyName": 'user_company2',
        "password": 'pass',
        "type": 'company',
        "firstname": 'John',
        "lastname": 'Doe',
        "email": 'comp_waiting@gmail.com',
        "overview": "EcoConnect is a sustainability-driven company focused on providing eco-friendly solutions across various industries. We aim to reduce environmental impact by offering sustainable products and services.",
        "mission": "Our mission is to create a greener, more sustainable future by offering eco-friendly products, reducing carbon footprints, and promoting responsible environmental practices.",
        "industry": "apparel",
        "productsOffered": "outdoor-clothing, gear",
        "practiceDescription": "EcoConnect adheres to sustainable practices such as reducing waste, promoting recycling, using renewable energy in operations, and ensuring ethical sourcing of materials.",
        "certifications": "fair-trade-certified, bluesign-approved",
        "supportingDocs": [],
        "location": "USA",
        "address": "123 Eco Street, Green City, Earth",
        "zip": "12345",
        "companySize": "50-199 employees",
        "website": "https://www.ecoconnect.com",
        "socials": "https://www.linkedin.com/ecoconnect, https://www.twitter.com/ecoconnect",
        profileCompleted: true,
        profileApproved: false
    },
    {
        "companyName": 'user_company3',
        "password": 'pass',
        "type": 'company',
        "firstname": 'Sara',
        "lastname": 'Smith',
        "email": 'comp_approved@gmail.com',
        "overview": "EcoConnect is a sustainability-driven company focused on providing eco-friendly solutions across various industries. We aim to reduce environmental impact by offering sustainable products and services.",
        "mission": "Our mission is to create a greener, more sustainable future by offering eco-friendly products, reducing carbon footprints, and promoting responsible environmental practices.",
        "industry": "apparel",
        "productsOffered": "outdoor-clothing, gear",
        "practiceDescription": "EcoConnect adheres to sustainable practices such as reducing waste, promoting recycling, using renewable energy in operations, and ensuring ethical sourcing of materials.",
        "certifications": "fair-trade-certified, bluesign-approved",
        "supportingDocs": [],
        "location": "IT",
        "address": "123 Eco Street, Green City, Earth",
        "zip": "12345",
        "companySize": "50-199 employees",
        "website": "https://www.ecoconnect.com",
        "socials": "https://www.linkedin.com/ecoconnect, https://www.twitter.com/ecoconnect",
        profileCompleted: true,
        profileApproved: true
    }
];