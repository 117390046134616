import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

interface InfoBoxProps {
    children: React.ReactNode;
}

const Info = styled.p`
    color: ${colors.darkGreen};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #e6f3e6;
`;

const InfoBox: React.FC<InfoBoxProps> = ({ children }) => {
    return (
        <Info>
            {children}
        </Info>
    );
};

export default InfoBox;