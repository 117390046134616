import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../../components/Sidebar';
import { colors, TABS, URLS } from '../../../constants';
import Header from '../../../components/Header';
import { Supplier } from '../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { Container, Content, Title } from '../../../App';
import RoundButton from '../../../components/RoundButton';
import Table from '../../../components/Table';
import { useSupplier } from '../../../context/SupplierContext';

const columns = [
  {
    field: 'company',
    headerName: 'Company',
    filter: true,
  },
  {
    field: 'country',
    headerName: 'Country',
    filter: true,
  },
  {
    field: 'product_categories',
    headerName: 'Product Categories',
    filter: true,
  },
];

const FindSupplier: React.FC = () => {
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const { suppliers } = useSupplier();

  const filteredSuppliers = suppliers?.filter(supplier =>
    Object.values(supplier).some(value =>
      typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleClickOnRow = (row?: Supplier) => {
    navigate(URLS.supplierDetails, { state: { supplierId: row?.id } });
  }

  return (
    <>
      <Header />
      <Container>
        <Sidebar tab={TABS.suppliers} />
        
        <Content>
          <Title>Suppliers</Title>
          <Flex>
            <MainTable>
            <SearchBar
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder="Search suppliers..."
            />
              <Table
                height={519}
                rowData={filteredSuppliers}
                columns={columns}
                onRowClick={row => handleClickOnRow(row.data)}
              />
          </MainTable>
          </Flex>

          <RoundButtonWrapper>
            <RoundButton onClick={() => navigate(URLS.addSupplier)} text='Add new supplier' size={60}/>
          </RoundButtonWrapper>
        </Content>
      </Container>
    </>
  );
};

const MainTable = styled.div`
  flex: 1;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    border-color: ${colors.darkGreen};
    outline: none;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const RoundButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export default FindSupplier;