import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Supplier } from '../interfaces';
import { suppliers as suppliersMock } from '../mocks';

interface SupplierContextType {
  suppliers: Supplier[];
  setSuppliers: (suppliers: Supplier[]) => void
}

const SupplierContext = createContext<SupplierContextType>({} as SupplierContextType);

export const SupplierProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [suppliers, setSuppliers] = useState<Supplier[]>(suppliersMock);

  return (
    <SupplierContext.Provider value={{ suppliers, setSuppliers }}>
      {children}
    </SupplierContext.Provider>
  );
};

export const useSupplier = (): SupplierContextType => {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error('useSupplier must be used within a AppProvider');
  }
  return context;
};
