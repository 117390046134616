import React from 'react';
import { colors } from '../../constants';
import styled from 'styled-components';

type Option = { value: string; label: string };

interface SelectProps {
    label?: string;
    value: string;
    options: Option[];
    name: string;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    multiple?: boolean;
    disabled?: boolean;
}

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 37px;
  padding: 7px;
  font-size: 14px;
  border: 1px solid ${colors.mediumGreen};
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-family: Poppins, Arial, Helvetica, sans-serif;

  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url(/assets/chevron-down.svg) no-repeat center / contain;
  background-color: white;
	background-size: 15px;
	background-position: calc(100% - 10px);
  color: #000000;

  &:focus, &:focus-visible {
    border-color: ${colors.darkGreen};
    box-shadow: 0 0 5px rgba(102, 187, 106, 0.5);
    outline: none;
  }

  &:disabled {
    background-color: #f7f7f7;
    border-color: #e0e0e0;
    color: #000000;  
  }
`;

const Select: React.FC<SelectProps> = ({ multiple, disabled, label, name, value, options, onChange, required }) => {
    return (
        <FormGroup>
            { label && <Label htmlFor={name}>{label}</Label> }
            <StyledSelect
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                multiple={multiple}
                disabled={disabled}
            >
                <option value="">Select</option>

                { options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                )) }
            </StyledSelect>
        </FormGroup>
    );
};

export default Select;