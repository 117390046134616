import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import { colors, TABS } from '../../constants';
import Header from '../../components/Header';
import Input from '../../components/Input';
import { User, useUser } from '../../context/UserContext';
import FileUpload from '../../components/FileUpload';
import MultiSelect from '../../components/Multiselect';
import Select from '../../components/Select';
import { industries, productsOfferedMock, certificationsMock, companySizes, countries_ } from '../../mocks';
import Button from '../../components/Button';

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;


const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const Column = styled.div`
    display: flex;   
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    flex-basis: 50%;
`;

const InputsContainer = styled.div`
    display: flex;
    gap: 40px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: 400px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    > button {
        margin-bottom: 10px;
    }
`;

const Profile: React.FC = () => {
  const { user, setUser } = useUser();
  
  const {
    companyName,
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    location,
    address,
    zip,
    companySize,
    website,
    socials
  } = user || {};

  const [formData, setFormData] = React.useState({
    companyName,
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    location,
    address,
    zip,
    companySize,
    website,
    socials
  });

  const saveAccount = async (): Promise<{ data: User }> => {
    if (!user) return { data: {} as User };

    const userData = {
        ...user,
        overview: formData.overview,
        mission: formData.mission,
        industry: formData.industry,
        productsOffered: formData.productsOffered,
        practiceDescription: formData.practiceDescription,
        certifications: formData.certifications,
        supportingDocs: formData.supportingDocs,
        location: formData.location,
        address: formData.address,
        zip: formData.zip,
        companySize: formData.companySize,
        website: formData.website,
        socials: formData.socials
    };

    setUser(userData)

    return { data: userData }
  };

  const submitAccount = async (e: React.FormEvent): Promise<{ data: User }> => {
    e.preventDefault();

    if (!user) return { data: {} as User };

    const { data } = await saveAccount();

    console.log({ data })

    setUser(data)

    return { data }
  };

  return (
    <>
    <Header />
    <Container>
      <Sidebar tab={TABS.profile} />
      
      <Content>
        <Title>Profile</Title>
        <form onSubmit={submitAccount}>
            <InputsContainer>
            <Column>
                <Input
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName || ''}
                />

                <Input
                    label="Company overview (Max 300 characters)"
                    name="overview"
                    textarea
                    maxLength={300}
                    value={formData.overview || ''}
                    onChange={(value) => setFormData({...formData, overview: value})}
                />

                <Input
                    label="Mission statement (Max 200 characters)"
                    name="mission"
                    textarea
                    maxLength={200}
                    value={formData.mission || ''}
                    onChange={(value) => setFormData({...formData, mission: value})}
                />

                <Select 
                    label='Company Industry'
                    name='industry'
                    value={formData.industry || ''}
                    onChange={(e) => setFormData({...formData, industry: e.target.value})}
                    options={industries}
                />

                <MultiSelect
                    label="Products/Services Offered"
                    name="productsOffered"
                    selectedValues={formData.productsOffered ? formData.productsOffered.split(', ') : []}
                    options={productsOfferedMock}
                    onChange={(value) => {
                      setFormData({...formData, productsOffered: value.join(', ')})
                    }}
                />

                <Input
                    label="Describe your sustainability practice"
                    name="practiceDescription"
                    value={formData.practiceDescription || ''}
                    onChange={(value) => setFormData({...formData, practiceDescription: value})}
                />

                <MultiSelect
                    label="Certifications"
                    name="certifications"
                    selectedValues={formData.certifications ? formData.certifications.split(', ') : []}
                    options={certificationsMock}
                    onChange={(value) => setFormData({...formData, certifications: value.join(', ')})}
                />

                <Input
                    label="Your supporting documents"
                    type="file"
                    multiple
                    name="documents"
                    onUpload={(files) => {
                        const documents = files?.map(file => { return { attachment: file, name: file?.name }});
                        setFormData({...formData, supportingDocs: documents})
                    }}
                />  
                </ Column>
                
                <Column>

                <FormGroup>
                    <Label htmlFor="role">Company logo</Label>
                    <FileUpload type='picture' />
                </FormGroup>

                <Select 
                    label='Headquarters Location'
                    name='location'
                    value={formData.location || ''}
                    onChange={(e) => setFormData({...formData, location: e.target.value})}
                    options={countries_}
                />

                <Input
                    label="Address"
                    name="address"
                    value={formData.address || ''}
                    onChange={(value) => setFormData({...formData, address: value})}
                />  

                <Input
                    label="ZIP/ Postal code"
                    name="zip"
                    value={formData.zip || ''}
                    onChange={(value) => setFormData({...formData, zip: value})}
                />  

                <Select  
                    label="Company size"
                    options={companySizes}
                    name="companySize"
                    onChange={(e) => setFormData({...formData, companySize: e.target.value})}
                    value={formData.companySize || ''}
                />  

                <Input  
                    label="Company Website"
                    name="website"
                    value={formData.website || ''}
                    onChange={(value) => setFormData({...formData, website: value})}
                />  

                <Input  
                    label="Social Media Account(s)"
                    name="socials"
                    textarea
                    value={formData.socials || ''}
                    onChange={(value) => setFormData({...formData, socials: value})}
                />  
            </Column>
            </InputsContainer>
            <ButtonsContainer>
              <Button type="submit">Save</Button>
            </ButtonsContainer>
        </form>
      </Content>
    </Container>
    </>
  );
};



export default Profile;