export const colors = {
    darkGreen: "#196919",
    mediumGreen: "#4caf50",
    lightGreen: "#e6f3e6",
    darkGreenHover: "#0c320c",
    lightGrey: "#f7f7f7",
    orange: "#d6b57d",
    red: "#ff0000",
    tableBorder: "#e9e9e9"
  };

  export const URLS = {
    home: '/',
    terms: '/terms-and-conditions',
    suppliers: '/suppliers',
    supplierDetails: '/suppliers/details',
    contracts: '/contracts',
    dashboard: '/dashboard',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    signupCompany: '/signup/company',
    addSupplier: '/suppliers/add-supplier',
    firstLogin: '/welcome',
    profile: '/profile', 
  }

  export const TABS = {
    suppliers: 'suppliers',
    contracts: 'contracts',
    dashboard: 'dashboard',
    profile: 'profile',
  }