import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';

interface CheckboxProps {
    label: React.ReactElement | string;
    checked: boolean;
    onChange?: (checked: boolean) => void;
    name: string;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
}

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const Checkbox: React.FC<CheckboxProps> = ({ style, label, name, checked, onChange, labelStyle }) => {
    return (
        <CheckboxContainer style={style}>
            <CheckboxInput
                type='checkbox'
                id={name}
                name={name}
                checked={checked}
                onChange={(e) => onChange && onChange(e.target.checked)}
            />
            <Label htmlFor={name} style={labelStyle}>
                { label }
            </Label>
        </CheckboxContainer>
    );
};

export default Checkbox;