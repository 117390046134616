import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Input from '../Input';

interface PasswordInputProps {
    value: string;
    onChange: (value: string) => void;
    name: string;
    label: string;
    required?: boolean;
    type?: string;
    placeholder?: string;
    forgotPasswordLink?: string;
}

const TogglePasswordButton = styled.button`
    position: absolute;
    right: 10px;
    top: 53px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: ${colors.darkGreen};
    cursor: pointer;
`;

const Wrapper = styled.div`
    position: relative;
`;

const ForgotPasswordLink = styled.a`
    color: ${colors.darkGreen};
    text-align: right;
    margin-top: 10px;
    width: 100%;
    display: block;
    margin-bottom: 20px;
`;


const PasswordInput: React.FC<PasswordInputProps> = ({ label, required, placeholder, name, value, onChange, forgotPasswordLink }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePassword = () => {
            setShowPassword(!showPassword);
    };

    return (
        <Wrapper>
            <Input
                label={label}
                name={name}
                value={value}
                type={showPassword ? 'text' : 'password'}
                onChange={(value) => onChange(value)}
                required={required}
                placeholder={placeholder}
                style={{ marginBottom: forgotPasswordLink ? '0' : '10px' }}
            />  
            <TogglePasswordButton onClick={handleTogglePassword}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
            </TogglePasswordButton>
            { forgotPasswordLink && <ForgotPasswordLink href={forgotPasswordLink}>Forgot password?</ForgotPasswordLink> }
        </Wrapper>
    );
};

export default PasswordInput;