import styled from "styled-components";
import Header from "../../components/Header";

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Content = styled.div`
   padding: 20px 20px;
   max-width: 800px;
   margin: 0 auto;

   h2 {
    padding: 20px 0;
   }

   p {
    padding-bottom: 20px;
   }
`;

const Terms = () => {
  return (
    <>
    <Header />
    <Wrapper>
      <Content>
        <h2>Terms and Conditions</h2>
        <p>
          1. Introduction<br></br>
          Welcome to EcoConnect Hub (&quot;we,&quot; &quot;our,&quot; or
          &quot;us&quot;). By accessing or using our platform, you agree to
          comply with and be bound by these Terms and Conditions
          (&quot;Terms&quot;). Please read these Terms carefully before using
          our services. If you do not agree with these Terms, you must not use
          our platform.
        </p>
        <p>
          2. Eligibility<br></br>
          By using the EcoConnect Hub, you represent and warrant that you are at
          least 18 years old or have the legal capacity to enter into these
          Terms. If you are using the platform on behalf of an organization, you
          represent and warrant that you have the authority to bind that
          organization to these Terms.
        </p>
        <p>
          3. Account Registration<br></br>
          To access certain features of our platform, you may need to create an
          account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account password and for any
          activities or actions under your account.
        </p>
        <p>
          4. Use of the Platform<br></br>
          You agree to use the EcoConnect Hub only for lawful purposes and in
          accordance with these Terms. You agree not to:<br></br>- Use the
          platform in any way that violates any applicable local, state,
          national, or international law or regulation.<br></br>- Engage in any
          activity that could disable, overburden, damage, or impair the
          platform or interfere with any other party's use of the platform.
          <br></br>- Attempt to gain unauthorized access to any portion of the
          platform or any related systems or networks.<br></br>- Use any robot,
          spider, or other automatic devices, processes, or means to access the
          platform for any purpose.
        </p>
        <p>
          5. Intellectual Property<br></br>
          All content on the EcoConnect Hub, including but not limited to text,
          graphics, logos, images, and software, is the property of EcoConnect
          Hub or its licensors and is protected by copyright, trademark, and
          other intellectual property laws. You may not use, reproduce,
          distribute, or create derivative works from any content on the
          platform without our prior written consent.
        </p>

        <p>
          6. User Content<br></br>
          You may submit, post, or share content through the platform
          (&quot;User Content&quot;). By submitting User Content, you grant us a
          non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and
          sub-licensable license to use, reproduce, modify, adapt, publish,
          translate, create derivative works from, distribute, and display such
          User Content. You represent and warrant that you own or have the
          necessary rights to use and share your User Content and that your User
          Content does not violate these Terms or any applicable law.
        </p>
        <p>
          7. Third-Party Links<br></br>
          The EcoConnect Hub may contain links to third-party websites or
          services that are not owned or controlled by us. We are not
          responsible for the content, privacy policies, or practices of any
          third-party websites or services. You acknowledge and agree that we
          are not liable for any damages or losses caused by your use of or
          reliance on any third-party websites or services.
        </p>
        <p>
          8. Termination<br></br>
          We may terminate or suspend your access to the EcoConnect Hub, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach these Terms. Upon termination, your
          right to use the platform will immediately cease.
        </p>
        <p>
          9. Limitation of Liability<br></br>
          To the fullest extent permitted by law, in no event shall EcoConnect
          Hub, its affiliates, directors, employees, or agents be liable for any
          indirect, incidental, special, consequential, or punitive damages,
          including but not limited to loss of profits, data, use, goodwill, or
          other intangible losses, arising out of or in connection with your use
          of the platform.
        </p>
        <p>
          10. Disclaimer of Warranties<br></br>
          The EcoConnect Hub is provided &quot;as is&quot; and &quot;as
          available,&quot; without any warranties of any kind, either express or
          implied, including but not limited to implied warranties of
          merchantability, fitness for a particular purpose, non-infringement,
          or course of performance. We do not warrant that the platform will be
          uninterrupted, secure, or error-free, or that any defects or errors
          will be corrected.
        </p>
        <p>
          11. Indemnification<br></br>
          You agree to indemnify, defend, and hold harmless EcoConnect Hub, its
          affiliates, directors, employees, and agents from and against any and
          all claims, damages, obligations, losses, liabilities, costs, or debt,
          and expenses (including but not limited to attorney&#39;s fees)
          arising from or in connection with your use of the platform, your
          violation of these Terms, or your violation of any rights of a third
          party.
        </p>
        <p>
          12. Governing Law<br></br>
          These Terms shall be governed and construed in accordance with the
          laws of United Arab Emirates, without regard to its conflict of law
          provisions. You agree to submit to the personal jurisdiction of the
          courts located within United Arab Emirates for the resolution of any
          disputes arising out of or relating to these Terms or your use of the
          platform.
        </p>
        <p>
          13. Changes to These Terms<br></br>
          We reserve the right to modify or replace these Terms at any time. If
          we make material changes, we will provide you with notice, such as by
          posting the revised Terms on the platform or through other
          communications. Your continued use of the platform after the effective
          date of the revised Terms constitutes your acceptance of the changes.
        </p>
        <p>
          14. Contact Us<br></br>
          If you have any questions or concerns about these Terms, please
          contact us at hello@ecoconnect- hub.com.
        </p>
      </Content>
    </Wrapper>
    </>
  );
};

export default Terms;
