import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import { TABS } from '../../constants';
import Header from '../../components/Header';
import { useApp } from '../../context/AppContext';
import { Container, Content, LinkButton, Title } from '../../App';

const Dashboard: React.FC = () => {

  const { handleTabClick } = useApp();

  return (
    <>
    <Header />
    <Container>
      <Sidebar tab={TABS.dashboard} />
      
      <Content>
        <Title>Dashboard</Title>
        <Main>
          <Info>Go check suppliers to start</Info>
          <LinkButton onClick={() => handleTabClick(TABS.suppliers)}>Find Suppliers</LinkButton>
        </Main>
      </Content>
    </Container>
    </>
  );
};

const Main = styled.div`
  background-color: #ffffff;
  padding: 20px;
`;

const Info = styled.p`
  font-size: 14px;
  text-align: center;
  padding: 30px;
`;

export default Dashboard;